const CButtonsPanel = require('../../index.js');
const config = require('./config.yaml');
const text = require('./text.yaml');

/**
 * Реализует логику варианта in-column компонента buttons-panel
 */
class CButtonsPanel_inColumn extends CButtonsPanel {
	constructor() {
		super();
		this.config = config;
		this.text = text;
	}

	init() {
		const panelClass = `.js-buttons-panel--${this.config['modifier']}`;
		this.tourSelector = [panelClass, '.hopscotch-nav-button'].join(', ');

		const $panel = $(panelClass);

		if ($panel.length) {
			$panel.each((i, elem) => {
				const panelObj = {};
				panelObj.$panel = $(elem);
				const isCompact = panelObj.$panel.hasClass('js-buttons-panel--compact');
				const $shareLinks = panelObj.$panel.find('[data-share-link]');
				panelObj.tourSelector = this.tourSelector;
				panelObj.panelClass = panelClass;
				panelObj.listOptions = this.config['animation']['list'];
				panelObj.sublistOptions = this.config['animation']['sublist'];
				panelObj.classesObj = {
					buttonsTrigger: '.js-buttons-panel-trigger',
					buttonsList: '.js-buttons-panel-list',
					sublistTrigger: '.js-buttons-panel-sublist-trigger',
					sublistLink: '.js-buttons-panel-sublist-link',
					sublist: '.js-buttons-panel-sublist',
					compare: '.js-buttons-panel-sublist-trigger--compare',
					compareList: '.js-buttons-panel-sublist--compare'
				};
				panelObj.$buttonsList = panelObj.$panel.find(panelObj.classesObj['buttonsList']);
				panelObj.$buttonsTrigger = panelObj.$panel.find(panelObj.classesObj['buttonsTrigger']);
				panelObj.$sublistTrigger = panelObj.$panel.find(panelObj.classesObj['sublistTrigger']);
				panelObj.$sublist = panelObj.$panel.find(panelObj.classesObj['sublist']);
				panelObj.$compare = panelObj.$panel.find(panelObj.classesObj['compare']);
				panelObj.$compareList = panelObj.$panel.find(panelObj.classesObj['compareList']);
				panelObj.events = {
					list: this.config['hoverablePanel'] ? this.config['events']['list'].hover : this.config['events']['list'].click,
					sublist: this.config['hoverableSublist'] ? this.config['events']['sublist'].hover : this.config['events']['sublist'].click
				};
				panelObj.dontCollapseSelector = [
					panelObj.classesObj['sublistTrigger'],
					`${panelClass} ${panelObj.classesObj['buttonsTrigger']}`,
					`${panelObj.classesObj['sublistLink']}:not([data-popup-trigger-id])`,
					'.js-add-page',
					'.js-del-page',
					'.js-no-collapse'
				].join(', ');

				if (this.config['hoverablePanel']) {
					panelObj.dontCollapseSelector += `,${`.js-buttons-panel--${this.config['modifier']}`}`;
				}

				panelObj.tour = null;
				if (config.tools.showTour && panelObj.$panel.attr('data-show-tour')) {
					const tourConfig = require('../../tourConfig.js')(this.text[templateVars.lang]);
					const sorting = config.tools.sorting[templateVars.lang];
					let tourSteps = tourConfig.steps;
					tourSteps = this.sortTourSteps(tourSteps, sorting);
					tourSteps[0].delay = '100';
					tourConfig.id += this.config['modifier'];

					tourSteps.forEach(step => {
						step.target = `${panelClass}[data-show-tour] ${step.target}`;
					});

					panelObj.tour = AR.components.cTour_base.createTour(tourConfig);
				}


				if (panelObj.$compare.length) {
					this.fixCompare(panelObj);
				}
				this.checkForItems(panelObj);
				this.initMyReportButtons(panelObj);

				//Показать и скрыть панель по табу
				this.showAndHideByTab(panelObj);

				$shareLinks.on('click', $.proxy(this, 'openShareLink'));
				panelObj.$sublistTrigger.on(panelObj.events.sublist.show, $.proxy(this, 'toggleHandler', panelObj));

				if (isCompact) {
					panelObj.$buttonsTrigger.on(panelObj.events.list.show, $.proxy(this, 'toggleHandler', panelObj));
				}
			});
		}
	}

	toggleHandler(panelObj, event) {
		event.preventDefault();
		const $this = $(event.currentTarget);
		const isSublist = $this.hasClass(panelObj.classesObj['sublistTrigger'].replace('.', ''));
		const $targetList = isSublist ? $this.siblings('.js-buttons-panel-sublist') : panelObj.$buttonsList;
		const animationOptions = isSublist ? panelObj.sublistOptions : panelObj.listOptions;
		const hoverable = isSublist ? this.config.hoverableSublist : this.config.hoverablePanel;

		this.hideSublists($this, panelObj);

		if (hoverable) {
			if (!$targetList.hasClass('is-expand')) {
				this.showList($this, panelObj, animationOptions, isSublist);
			}
		} else {
			this.toggleList($this, panelObj, animationOptions, $targetList, isSublist);
		}
	}


	showList($elem, panelObj, options, isSublist) {
		this.enableDocumentListener(panelObj, isSublist);

		if (//закрывать прочие панели при открытии выбранных
			($elem.hasClass('b-buttons-panel__trigger--download-pdf') && window.innerWidth > 812) ||
			$elem.hasClass('b-buttons-panel__trigger--tools')
		) {
			$('.js-buttons-panel-trigger.is-expand').click();
			$('.js-mobile-menu-burger.is-expand').click();
		}

		$elem
			.addClass('is-expand')
			.siblings('.js-buttons-panel-list, .js-buttons-panel-sublist')
			.addClass('is-expand')
			.velocity(options.show.effect, {
				duration: options.show.duration,
				complete: () => {
					if (panelObj.tour && panelObj.$panel.closest('.js-buttons-panel--tools')) {
						AR.components.cTour_base.startFirstTime(panelObj.tour);
					}
				}
			});
	}


	onDocumentListenerSublist(panelObj, event) {
		super.onDocumentListenerSublist(panelObj, event);
	}

	onDocumentListener(panelObj, event) {
		super.onDocumentListener(panelObj, event);
	}
}

AR.waitComponents(['cMyReport_base', 'cTour_base'], () => {
	const cButtonsPanel_inColumn = new CButtonsPanel_inColumn();
	// Вызов метода, инициализирующего все существующие события
	cButtonsPanel_inColumn.init();

	// Добавление в глобальный объект AR.components
	AR.pushComponent(cButtonsPanel_inColumn, 'cButtonsPanel_inColumn');
});
