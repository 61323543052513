const config = require('./config.yaml');
const {CountUp} = require('countup.js');
const CKeyIndicator = require('../../index.js');
const isElementInView = require('component/core/.variant/base/additionals/isElementInView.js');
const {throttle} = require('throttle-debounce');

/**
* Реализует логику варианта counter компонента key-indicators
*/
class CKeyIndicator_counter extends CKeyIndicator {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$counters = $('.js-indicator--counter');

		if (this.$counters.length) {
			this.$counters.each((i, elem) => {
				const $value = $(elem).find('.js-indicator-value');

				if ($value.length) {
					this.createCopyForPrint($value);
					this.createCounter($value);
				}
			});
		}
	}

	/**
	 * Создать каунтер
	 * @param {object} $value блок со значением
	 * @param {object} startHandler callback вызываемый при начале анимации
	 */
	createCounter($value, startHandler) {
		const options = this.config.options[$value.data('counterLang')] || this.config.options[templateVars.lang];
		const startVal = this.getCounterValue($value.data('counterStartVal') || this.config.startVal, options);
		const endVal = this.getCounterValue($value.data('counterEndVal') || $value.text(), options);
		const decimalPlaces = $value.data('counterDecimals') || this.getDecimals(endVal, options);
		const duration = $value.data('counterDuration') || this.config.duration;

		const counter = new CountUp(
			$value[0],
			endVal,
			{
				...options,
				startVal,
				decimalPlaces,
				duration
			}
		);

		this.checkAndStart($value, counter, startHandler);

		$(window).on('scroll', throttle(100, () => {
			this.checkAndStart($value, counter, startHandler);
		}));

		return counter;
	}

	/**
	 * Проверка на видимость каунтера в окне и запуск анимации (if true)
	 * @param {*} $value
	 * @param {*} counter
	 * @param {*} startHandler
	 */
	checkAndStart($value, counter, startHandler) {
		if (isElementInView($value, true)) {
			counter.start(() => {
				this.onStart(startHandler);
			});
		}
	}

	/**
	 * Вызов callback'a на начало анимирования каунтера
	 * @param {object} handler callback
	 */
	onStart(handler) {
		if (typeof handler == 'function') {
			handler();
		}
	}

	/**
	 * Удалить 0 на конце
	 * @param {string} value значение
	 */
	trimZeroOnEnd(value) {
		return value.replace(/0+$/g, '');
	}

	/**
	 * Получить значение каунтера
	 * @param {number, string} value значение
	 * @param {object} options опции
	 */
	getCounterValue(value, options) {
		if (!value) {
			return 0;
		} else {
			const separatorRegEx = new RegExp(`${options.separator}`, 'g');

			return String(value)
				.replace(options.decimal, '.')
				.replace(separatorRegEx, '');
		}
	}

	/**
	 * Получить количество цифр после запятой
	 * @param {string} value значение
	 */
	getDecimals(value) {
		const splittedValue = value.split('.');

		if (splittedValue.length > 1) {
			const pieceAfterDecimal = splittedValue.pop();

			if (this.config.trimZeroOnEnd) {
				return this.trimZeroOnEnd(pieceAfterDecimal).length;
			} else {
				return pieceAfterDecimal.length;
			}
		} else {
			return this.config.decimalPlaces;
		}
	}
}

AR.waitComponents([], () => {
	const cKeyIndicator_counter = new CKeyIndicator_counter();
	// Вызов метода со всеми событиями
	cKeyIndicator_counter.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cKeyIndicator_counter, 'cKeyIndicator_counter');
});
