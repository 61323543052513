const config = require('./config.yaml');
const platform = require('platform');
const osName = platform.os.family;
const CPageNav = require('../../index.js');

/**
 * Реализует логику варианта bottom компонента page-nav
 */
class CPageNav_bottom extends CPageNav {
	constructor() {
		super();
		this.config = config;
		this.platform = platform;
		this.osName = osName;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$pagenavBottom = $('.js-pagenav-bottom');

		if (this.$pagenavBottom.length) {
			this.$expandableButton = this.$pagenavBottom.find($('.js-expandable-button'));
			this.$pagenavLink = this.$pagenavBottom.find($('.js-pagenav-link'));
			this.$pagenavScrollUp = this.$pagenavBottom.find($('.js-scrollup'));
			this.$pagenavExpandable = $('.js-pagenav-expandable');
			this.$fixedArea = $('.js-fixed-area');
			this.scrollTopValue = this.config.scrollTopValue;
			$(window).on('scroll', $.proxy(this, 'setStaticPagenav', false));
			$(window).on('resize', $.proxy(this, 'setStaticPagenav', false));
			// $(document).on('touchstart', $.proxy(this, 'removeTouchClass'));
			this.$pagenavScrollUp.on('click', $.proxy(this, 'scrollUp'));
			// this.$pagenavScrollUp.on('touchstart', $.proxy(this, 'touchStart'));
			// $(document).on('keydown', $.proxy(this, 'checkKeyDown'));
			this.$expandableButton.on('click', $.proxy(this, 'clickExpandableButton'));
			// this.$pagenavLink.on('touchstart', $.proxy(this, 'touchStart'));

			if (this.config.setParentHeight) {
				this.$parent = this.$fixedArea.parent();
				this.setParentHeight();
			}

			AR.events.on('onFontSizeChanged', () => {
				this.setStaticPagenav();

				if (this.config.setParentHeight) {
					this.setParentHeight();
				}
			});

			this.setStaticPagenav(true);
			this.addHelpKey();

			this.$pagenavBottom.removeClass('b-pagenav--loading');

			if (!this.config.hideUpBtn && this.config.removeUpBtnOnScroll) {
				this.hideBtnSelector = this.config.removeUpBtnOnScroll ? '.js-scrollup-wrapper' : '.js-scrollup';

				$(window).on('scroll', $.proxy(this, 'toggleShowUpButton'));
				this.toggleShowUpButton();
			}

			if (!this.config.customTooltip && !(this.osName == 'Android' || this.osName == 'iOS')) {
				this.addHelpKeyToTitle();
			}
		}
	}

	/**
	 * Добавить комбинацию горячих клавиш для перелистывания в атрибут title
	 */
	addHelpKeyToTitle() {
		const key = this.osName == 'OS X' ? 'Alt' : 'Ctrl';
		const prevTitleText = `(${key} + ←)`;
		const nextTitleText = `(${key} + →)`;
		const $prevLink = this.$pagenavBottom.find('.js-pagenav-link--prev');
		const $nextLink = this.$pagenavBottom.find('.js-pagenav-link--next');

		if (!config.buttonWithText || config.hotkeyWithText) {
			$prevLink.attr('title', `${$prevLink.attr('title')} ${prevTitleText}`);
			$nextLink.attr('title', `${$nextLink.attr('title')} ${nextTitleText}`);
		} else {
			$prevLink.attr('title', prevTitleText);
			$nextLink.attr('title', nextTitleText);
		}
	}

	/**
	 * Показать навигацию
	 */
	showNavFromDown() {
		// Значение top
		const topValue = this.$fixedArea.offset().top;
		// Видимая часть (if > 0)
		const visiblePart = ($(window).scrollTop() + $(window).height()) - topValue;

		this.$fixedArea
		// Сместить вверх на видимую часть / всю высоту
			.css({
				bottom: -(this.pageNavHeight - (visiblePart > 0 ? visiblePart : 0))
			})
			// Плавно сместить к верху окна
			.velocity(this.config['showAnimationSettings'].props, this.config['showAnimationSettings'].options);
	}

	/**
	 * Скрыть / показать page-nav
	 */
	clickExpandableButton() {
		if (this.$pagenavExpandable.hasClass('is-close')) {
			this.$pagenavExpandable
				.addClass('is-open')
				.removeClass('is-close');
		} else {
			this.$pagenavExpandable
				.addClass('is-close')
				.removeClass('is-open');
		}
	}

	/**
	 * Задать высоту родительскому элементу page-nav
	 */
	setParentHeight() {
		this.pageNavHeight = this.$fixedArea.height();

		this.$parent.css({height: this.pageNavHeight, minHeight: this.pageNavHeight});
	}

	/**
	 * Открепить (fixed) / прикрепить page-nav
	 * @param {boolean} init вызов метода при инициализации
	 */
	setStaticPagenav(init = false) {
		let footerHeight = $('.l-footer').innerHeight();
		let documentHeight = $(document).height();
		let windowHeight = $(window).height();
		let $nav = $('.js-fixed-area.b-now-static-area');
		let navHeight = $nav.length ? $nav.height() : 0;
		let breakPointScroll = documentHeight - windowHeight - footerHeight - navHeight;

		//iOS
		if (platform.os.family == 'iOS') {
			breakPointScroll -= 65;
		}

		//Android
		if (platform.os.family == 'Android') {
			breakPointScroll -= 100;
		}

		if ($(window).scrollTop() >= breakPointScroll && !this.$fixedArea.hasClass('b-now-static-area')) {
			this.$fixedArea.addClass('b-now-static-area');
			this.$pagenavExpandable
				.removeClass('is-open')
				.removeClass('is-close');
			this.$expandableButton.hide();

			if (windowHeight === documentHeight && !this.$fixedArea.hasClass('b-fixed-area--above-footer')) {
				if (this.config.defaultAsStatic && init) {
					this.setParentHeight();
					this.showNavFromDown();
				}

				this.$fixedArea
					.removeClass('b-now-static-area')
					.addClass('b-fixed-area--above-footer');

			} else if (this.$fixedArea.hasClass('b-fixed-area--above-footer')) {
				this.$fixedArea.removeClass('b-fixed-area--above-footer');
			}
		} else if ($(window).scrollTop() + this.$pagenavBottom.height() - $('.js-pagenav-bottom').height() < breakPointScroll
			&& this.$fixedArea.hasClass('b-now-static-area')) {
			if (this.config.defaultAsStatic && init) {
				this.setParentHeight();
				this.showNavFromDown();
			}

			this.$fixedArea
				.removeClass('b-now-static-area')
				.removeClass('b-fixed-area--above-footer');

			this.$pagenavExpandable
				.addClass('is-close');
			this.$expandableButton.show();
		}
	}

	/**
	 * Установить состояние кнопки наверх
	 */
	toggleShowUpButton() {
		if ($(window).scrollTop() > this.scrollTopValue && $(this.hideBtnSelector).is(':hidden')) {
			this.$pagenavBottom.removeClass('pagenav--hidden-up');
		} else if ($(window).scrollTop() < this.scrollTopValue && $(this.hideBtnSelector).is(':visible')) {
			this.$pagenavBottom.addClass('pagenav--hidden-up');
		}

		this.setStaticPagenav();
	}
}

AR.waitComponents([], () => {
	const cPageNav_bottom = new CPageNav_bottom();
	// Вызов метода, инициализирующего все существующие события
	cPageNav_bottom.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cPageNav_bottom, 'cPageNav_bottom');
});
