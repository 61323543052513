import objectFitVideos from 'object-fit-videos';
import {throttle} from 'throttle-debounce';
objectFitVideos();
window.objectFitVideos = objectFitVideos;

if ($('.mainpage__wrapper').length) {
	let width;

	let recalcWidth = function () {
		let _width = window.innerWidth;
		if (_width > 812) {
			width = Math.ceil(window.innerWidth - $('.mainpage__link-wrapper.is-visible').width() * 3);

			// фиксируем ширину блоков иначе верстка разлезается
			$('.mainpage__content.is-visible').css({width: width + 'px'});
			$('.mainpage__content.is-hidden').css({width: '0px'});
		}
	};


	let clearCssD = function () {
		// очищаем стили и классы
		$('.mainpage__item').removeClass('is-active');
		$('.mainpage__content').css({width: ''}).removeClass('is-visible is-hidden');
		$('.mainpage__link-wrapper').css({opacity: ''}).removeClass('is-visible is-hidden');
		$('.mainpage__right div').css({transform: ''});


		// устанавливаем стили и классы для первого слайда
		$('.mainpage__item:first').addClass('is-active');
		$('.mainpage__content:first').css({width: '100%'}).addClass('is-visible');
		$('.mainpage__content:not(.is-visible)').css({width: '0px'}).addClass('is-hidden');
		let $active = $('.mainpage__content--1');
		$active.find('.mainpage__white-block')
			.css({transform: ''});
		$active.find('.mainpage__link-block')
			.css({transform: ''});
		$active.find('.mainpage__right')
			.css({transform: '', opacity: ''});

		//устанавливаем стили и классы для неактивных слайдов
		$('.mainpage__link-wrapper:first').addClass('is-hidden');
		$('.mainpage__link-wrapper:not(.is-hidden)').addClass('is-visible');
	};

	let clearCssM = function () {
		$('.mainpage__link-wrapper').css({opacity: 1});
		$('.mainpage__white-block,.mainpage__link-block,.mainpage__right').css({transform: ''});
		$('.mainpage__item').removeClass('is-active');
		$('.mainpage__content').removeClass('is-visible');
		$('.mainpage__link-wrapper').removeClass('is-hidden');
	};

	/**
	 * возвращает блоки в исходное состояние
	 */
	let width0 = null;
	let toogleDevice = function (force) {
		let _width = window.innerWidth;
		if (width0 == _width && Boolean(force) == false) {
			return;
		}
		width0 = _width;
		if (_width > 812) {//десктоп
			clearCssD();
		} else {//мобилка
			clearCssM();
		}
		setTimeout(() => {
			recalcWidth();
		}, 100);
	};
	toogleDevice();

	let resizeTimer;
	$(window).resize(function () {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(() => {
			toogleDevice();
		}, 140);
	});

	$(document).on('click', '.mainpage__close-wrapper', function () {
		toogleDevice(true);
	});

	let activateTab = function ($this) {
		//активность табов
		$('.mainpage__item').removeClass('is-active');
		$this.addClass('is-active');
		// обработка табов
		$('.mainpage__item').each(function () {
			let $slide = $(this);
			let isActive = $slide.hasClass('is-active');
			let $link = $slide.find('.mainpage__link-wrapper');
			let $content = $slide.find('.mainpage__content');

			// распределяем классы
			$link.removeClass('is-hidden is-visible');
			$content.removeClass('is-hidden is-visible');

			if (isActive) {
				$link.addClass('is-hidden');
				$content.addClass('is-visible');
			} else {
				$link.addClass('is-visible');
				$content.addClass('is-hidden');
			}
		});
	};

	if ($('html').hasClass('browser-ie')) {
		$('body').css({overflow: 'hidden'});
	}

	$(document).on('click', '.mainpage__item:not(.is-active)', function () {
		let _width = window.innerWidth;
		let $this = $(this);
		let $prev = $('.mainpage__item.is-active').addClass('is-passive');
		let prevTab = $prev.data('tab');
		let actTab = $this.data('tab');

		let animateInfo = function ($active) {
			$('.is-passive').removeClass('is-passive');

			let anime = {translateX: [0, -500], opacity: [1, 1]};
			if ($active.hasClass('mainpage__item mainpage__item--1')) {
				anime = {translateX: [0, 500], opacity: [1, 1]};
			}

			//анимируем инфоблоки
			let time = 1000;
			$active.find('.mainpage__white-block')
				.velocity('stop')
				.velocity({translateX: [0, -500]}, time);
			$active.find('.mainpage__link-block')
				.velocity('stop')
				.velocity({translateX: [0, -500]}, time);
			$active.find('.mainpage__right')
				.velocity('stop')
				.velocity(anime, time);
		};

		let animateInfoM = function ($this) {
			let hideSpeed = 1000;
			let showSpeed = 1000;
			//Анимация заголовка таба
			$('.mainpage__link-wrapper').css({opacity: 1});
			$this.find('.mainpage__link-wrapper')
				.velocity({opacity: [0, 1]}, hideSpeed);

			// анимация белого инфоблока
			let infoHeight = $this.find('.mainpage__info-block').height();
			$this.find('.mainpage__info-block')
				.velocity({
					height: [infoHeight, 0],
					opacity: [1, 0]}, showSpeed);

			$.Velocity.hook($this.find('.mainpage__right div'), 'translateX', '-100%');
			$this.find('.mainpage__right').css({opacity: 1});
			$this.find('.mainpage__right div')
				.velocity({
					translateX: ['0%', '-100%']
				}, showSpeed);
		};

		if (_width > 812) {
			$('.mainpage__item').css({'pointer-events': 'none'});
			//прячем инфоблоки
			let hideSpeed = 500;
			$('.mainpage__white-block')
				.velocity('stop')
				.velocity({translateX: [500, 0]}, hideSpeed);
			$('.mainpage__link-block')
				.velocity('stop')
				.velocity({translateX: [500, 0]}, hideSpeed);
			$('.mainpage__right')
				.velocity('stop')
				.velocity({translateX: [(prevTab == 1) ? 500 : -500, 0], opacity: [0, 1]}, hideSpeed, function () {

					activateTab($this);
					//анимируем слайды
					let slideSpeed = 1000;

					$('.mainpage__content.is-visible').velocity({width: [width + 'px', '0px']}, slideSpeed, () => {
						$('.mainpage__tablink').css({'pointer-events': ''});
						$('.mainpage__item').css({'pointer-events': ''});
					});

					if (prevTab < actTab) {
						$prev.find('.mainpage__content').css({
							left: -$('.mainpage__link-wrapper.is-visible').width(),
							position: 'relative',
						});
						$prev.find('.mainpage__link-wrapper')
							.css({left: width + 'px'})
							.velocity({left: ['0px', width + 'px']}, {duration: slideSpeed, complete: function () {
								$prev.find('.mainpage__content').css({
									left: '',
									position: ''
								});
							}});
					}

					$('.is-passive .mainpage__content.is-hidden').velocity({width: ['0px', width + 'px']}, slideSpeed, function () {
						let $active = $('.mainpage__item.is-active');
						animateInfo($active);
					});
				});


		} else {// мобилка
			activateTab($this);
			animateInfoM($this);
		}
	});
}
if ($('#video-preloader').length) {
	$('body').addClass('video-play');
	$('#video-preloader').on('ended', function () {
		$('.mainpage__preloader').addClass('is-hidden');

		//показываем тур если он скрыт
		$('body').removeClass('video-play');
		//запускаем тур если он еще не запущен
		AR.events.emit('restartTour');
	});
}

