module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'buttons-panel/in-column' && templateVars.config ?
				templateVars.config :
				{
	"modifier": "in-column",
	"ifEmptyPanel": "remove",
	"animation": {
		"list": {
			"show": {
				"effect": "transition.slideUpIn",
				"duration": 200
			},
			"hide": {
				"effect": "transition.slideDownOut",
				"duration": 200
			}
		},
		"sublist": {
			"show": {
				"effect": "slideDown",
				"duration": 200
			},
			"hide": {
				"effect": "slideUp",
				"duration": 200
			}
		}
	},
	"events": {
		"list": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		},
		"sublist": {
			"click": {
				"show": "click touchstart",
				"hide": "click touchstart"
			},
			"hover": {
				"show": "touchstart mouseenter",
				"hide": "mouseover touchstart"
			}
		}
	},
	"hoverablePanel": false,
	"hoverableSublist": true,
	"tools": {
		"panelType": "compact",
		"title": {
			"ru": "Инструменты",
			"en": "Tools"
		},
		"sublistSlideType": "bottom",
		"InMyReport": false,
		"buttonWithText": false,
		"offlineHideButtons": [
			"feedback",
			"share",
			"social"
		],
		"buttonsPanelHideButtons": [
			"compare",
			"glossary",
			"disclaimer",
			"archive",
			"analysis"
		],
		"hideButtonsOnSystem": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf",
			"share"
		],
		"hideButtonsOnMainPage": [
			"addToReport",
			"delFromReport",
			"printVersion",
			"pdf"
		],
		"hideCurrentButtonOnPage": true,
		"showTour": true,
		"sorting": {
			"ru": [
				"myReport",
				"addToReport",
				"delFromReport",
				"share",
				"history",
				"feedback",
				"pdf",
				"printVersion",
				"siteMap",
				"download",
				"compare",
				"glossary",
				"disclaimer",
				"archive"
			],
			"en": [
				"myReport",
				"addToReport",
				"delFromReport",
				"share",
				"history",
				"feedback",
				"pdf",
				"printVersion",
				"siteMap",
				"download",
				"compare",
				"glossary",
				"disclaimer",
				"archive"
			],
			"kz": [
				"myReport",
				"addToReport",
				"delFromReport",
				"share",
				"history",
				"feedback",
				"pdf",
				"printVersion",
				"siteMap",
				"download",
				"compare",
				"glossary",
				"disclaimer",
				"archive"
			]
		},
		"links": [
			{
				"id": "myReport",
				"modifier": "myreport",
				"targetBlank": false,
				"url": "${rootUrl}/my-report",
				"text": {
					"ru": "Мой отчет",
					"en": "My report",
					"kz": "Менің есебім"
				}
			},
			{
				"id": "addToReport",
				"modifier": "add js-add-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Добавить в «Мой отчет»",
					"en": "Add to My Report"
				}
			},
			{
				"id": "delFromReport",
				"modifier": "del js-del-page",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Удалить из «Моего отчета»",
					"en": "Delete from My Report"
				}
			},
			{
				"id": "printVersion",
				"modifier": "print js-print",
				"targetBlank": true,
				"url": "javascript:;",
				"text": {
					"ru": "Печать страницы",
					"en": "Print version",
					"kz": "Бетті басып шығару"
				},
				"event": "window.print();"
			},
			{
				"id": "pdf",
				"modifier": "pdf js-get-pdf",
				"targetBlank": true,
				"url": "${pdf}",
				"text": {
					"ru": "Скачать в PDF",
					"en": "PDF version",
					"kz": "PDF жүктеп алу"
				}
			},
			{
				"id": "share",
				"modifier": "share js-share",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Поделиться",
					"en": "Share",
					"kz": "Бөлісу"
				},
				"subList": [
					{
						"id": "shareVkontakte",
						"modifier": "vkontakte",
						"targetBlank": true,
						"url": "http://vkontakte.ru/share.php?url={{pageUrl}}&title={{pageTitle}}&image={{thisSiteUrl}}&description=",
						"text": {
							"ru": "Вконтакте",
							"en": "Vkontakte",
							"kz": "Vkontakte"
						}
					},
					{
						"id": "shareLinkedin",
						"modifier": "linkedin",
						"targetBlank": true,
						"url": "http://www.linkedin.com/shareArticle?mini=true&url={{pageUrl}}&title={{pageTitle}}&summary=&source={{thisSiteUrl}}",
						"text": {
							"ru": "Linkedin",
							"en": "Linkedin",
							"kz": "Linkedin"
						}
					},
					{
						"id": "shareTwitter",
						"modifier": "twitter",
						"targetBlank": true,
						"url": "http://twitter.com/share?text={{pageTitle}}&url={{pageUrl}}",
						"text": {
							"ru": "Twitter",
							"en": "Twitter",
							"kz": "Twitter"
						}
					},
					{
						"id": "shareFacebook",
						"modifier": "facebook",
						"targetBlank": true,
						"url": "https://www.facebook.com/sharer/sharer.php?u={{pageUrl}}&t={{pageTitle}}",
						"text": {
							"ru": "Facebook",
							"en": "Facebook",
							"kz": "Facebook"
						}
					},
					{
						"id": "shareEmail",
						"modifier": "email js-share-form-button",
						"targetBlank": false,
						"url": "",
						"text": {
							"ru": "E-mail",
							"en": "E-mail",
							"kz": "E-mail"
						}
					}
				]
			},
			{
				"id": "history",
				"modifier": "history",
				"targetBlank": false,
				"url": "${rootUrl}/history",
				"text": {
					"ru": "История",
					"en": "Browsing history",
					"kz": "Қаралған беттердің тарихы"
				}
			},
			{
				"id": "feedback",
				"modifier": "feedback js-feedback-form-button",
				"targetBlank": false,
				"url": "javascript:;",
				"text": {
					"ru": "Обратная связь",
					"en": "Feedback",
					"kz": "Кері байланыс"
				}
			},
			{
				"id": "download",
				"modifier": "download",
				"targetBlank": false,
				"url": "${rootUrl}/downloads",
				"text": {
					"ru": "Центр загрузки",
					"en": "Download center",
					"kz": "Жүктеу орталығы"
				}
			},
			{
				"id": "compare",
				"modifier": "compare",
				"targetBlank": true,
				"url": "${rootUrl}",
				"text": {
					"ru": {
						"compare": "Предыдущий отчет",
						"see": "Смотреть предыдущий отчет",
						"yearText": "",
						"compareList": "Предыдущие отчеты",
						"seeList": "Смотреть предыдущие отчеты",
						"previousReports": "Предыдущие отчеты"
					},
					"en": {
						"compare": "Previous report",
						"see": "See previous report",
						"yearText": "",
						"compareList": "Previous reports",
						"seeList": "See previous reports",
						"previousReports": "Previous reports"
					}
				}
			},
			{
				"id": "siteMap",
				"modifier": "site-map",
				"targetBlank": false,
				"url": "${rootUrl}/sitemap",
				"text": {
					"ru": "Карта сайта",
					"en": "Sitemap"
				}
			},
			{
				"id": "glossary",
				"modifier": "glossary",
				"targetBlank": false,
				"url": "${rootUrl}/glossary/",
				"text": {
					"ru": "Глоссарий",
					"en": "Glossary"
				}
			},
			{
				"id": "archive",
				"modifier": "archive-reports",
				"targetBlank": false,
				"url": "${rootUrl}/archive/",
				"text": {
					"ru": "Предыдущие отчеты",
					"en": "Previous reports"
				}
			},
			{
				"id": "disclaimer",
				"modifier": "disclaimer",
				"targetBlank": false,
				"url": "${rootUrl}/disclaimer/",
				"text": {
					"ru": "Ограничение ответственности",
					"en": "Disclaimer"
				}
			}
		]
	},
	"download-pdf": {
		"panelType": "compact",
		"title": {
			"ru": "Скачать PDF",
			"en": "Download PDF",
			"kz": "PDF жүктеп алу"
		},
		"slideType": "vertical",
		"buttonWithText": false,
		"offlineHideButtons": [],
		"buttonsPanelHideButtons": [],
		"sorting": {
			"ru": [
				"pdfPages",
				"pdfSpreads"
			],
			"en": [
				"pdfPages",
				"pdfSpreads"
			],
			"kz": [
				"pdfPages",
				"pdfSpreads"
			]
		},
		"links": [
			{
				"id": "pdfPages",
				"modifier": "pdf-pages",
				"targetBlank": true,
				"url": "/download/full-reports/${site}_${lang}_annual-report_pages_kmg_2019.pdf",
				"text": {
					"ru": "Постранично",
					"en": "Page by page",
					"kz": "Бір парақтан"
				},
				"trackerParams": {
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-pages"
					},
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-pages"
					},
					"kz": {
						"type": "click",
						"url": "kz_buttons-panel-download-pdf_download-pdf-pages"
					}
				}
			},
			{
				"id": "pdfSpreads",
				"modifier": "pdf-spreads",
				"targetBlank": true,
				"url": "/download/full-reports/${site}_${lang}_annual-report_spreads_kmg_2019.pdf",
				"text": {
					"ru": "Разворотами",
					"en": "Spreads",
					"kz": "екі парақтан"
				},
				"trackerParams": {
					"en": {
						"type": "click",
						"url": "en_buttons-panel-download-pdf_download-pdf-spreads"
					},
					"ru": {
						"type": "click",
						"url": "ru_buttons-panel-download-pdf_download-pdf-spreads"
					},
					"kz": {
						"type": "click",
						"url": "kz_buttons-panel-download-pdf_download-pdf-spreads"
					}
				}
			}
		]
	}
};