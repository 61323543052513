module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'mobile-menu/vertical-accordeon' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"menuTitle",
		"shortTitle",
		"titleById"
	],
	"expandCurrentOnLoad": false,
	"depthLevel": 4,
	"hideInnerOnClose": false,
	"hidePreviousListOnExpand": true,
	"trackerParamsKeyConfig": "topMenu",
	"triggerPosInPanel": {
		"top": "11px",
		"right": "15px"
	},
	"animation": {
		"menuItem": {
			"open": {
				"transition": "slideDown",
				"duration": 200
			},
			"close": {
				"transition": "slideUp",
				"duration": 200
			}
		},
		"panel": {
			"open": {
				"transition": "transition.slideRightBigIn",
				"duration": 250
			},
			"close": {
				"transition": "transition.slideRightBigOut",
				"duration": 250
			}
		}
	},
	"additionalLinks": {
		"ar": {
			"ru": [
				{
					"title": "Дополнительные ссылки",
					"static": true,
					"children": [
						{
							"title": "Политика Cookie",
							"link": "${rootUrl}/cookies-policy/",
							"targetBlank": false
						}
					]
				}
			],
			"en": [
				{
					"title": "Additional links",
					"static": true,
					"children": [
						{
							"title": "Cookies policy",
							"link": "${rootUrl}/cookies-policy/",
							"targetBlank": false
						}
					]
				}
			],
			"kz": [
				{
					"title": "Additional links",
					"static": true,
					"children": [
						{
							"title": "Cookies policy",
							"link": "${rootUrl}/cookies-policy/",
							"targetBlank": false
						}
					]
				}
			]
		}
	}
};