const config = require('./config.yaml');
const text = require('./text.yaml')[templateVars.lang];
const CZoomIn = require('../../index.js');
import addExcelExport from 'component/excel-export/.variant/base';
import initChartByContainerID from 'component/charts/.variant/base/';

/**
 * Класс реализует логику варианта base для компонента zoomIn
 */
class CZoomIn_base extends CZoomIn {
	constructor() {
		super();
		this.config = config;
		this.text = text;
		this.buttonTitle = this.text.title;
		this.cBoxContainerSelector = '#colorbox';
		this.cBoxLoadedContentSelector = '#cboxLoadedContent';
		this.cBoxCloseSelector = '#cboxClose';

		this.defaultOrientSizeParams = {
			width: '95%',
			height: false
		};

		this.orientSizeParams = {
			width: '95%',
			height: false
		};
	}

	/**
	 * Инициализация
	 */
	init() {
		$(window).on('resize', $.proxy(this, 'resizeColorBox'));
		$(window).on('orientationchange', $.proxy(this, 'resizeColorBox'));
	}

	/**
	 * Ресайзнуть colorbox (модалку)
	 */
	resizeColorBox() {
		if ($('#cboxOverlay').is(':visible')) {
			if ($(window).width() < parseFloat(this.config['maxWidth'])) {
				this.setGraphicWidth();

				this.updateColorbox({
					width: '95%'
				});
			}
		}
	}

	/**
	 * Задать графику внутри модалки ширину 100%
	 */
	setGraphicWidth() {
		$(this.cBoxContainerSelector).find('.graphic').width('100%');
	}

	/**
	 * Обработчик события клика по кнопке zoom-in
	 * @param event
	 * @param element
	 */
	registerEvent(event, element) {
		event.preventDefault();

		this.orientSizeParams = $.extend({}, this.defaultOrientSizeParams);

		const $element = $(element);
		const elementType = CZoomIn_base.getElementType($element);

		switch (elementType) {
			case 'IMAGE':
				this.actionColorBoxImage($element);
				break;
			case 'TABLE':
				this.actionColorBoxTable($element);
				break;
			case 'SVG':
				this.actionColorBoxSVG($element);
				break;
			case 'INTERACTIVE_CHART':
				this.actionColorBoxInteractiveChart($element);
				break;
			case 'HIGHCHARTS':
				this.actionColorBoxHighcharts($element);
				break;
			case 'OTHER':
				this.actionColorBox($element);
				break;
		}

		global.blurOnLeave(this);
	}

	/**
	 * Настройки пропорций в зависимости от ориентации
	 * @param orient - тип ориентации (width | height)
	 */
	setOrientSize(orient) {
		if (orient == 'width') {
			if (!this.config.fixedMaxWidth && $(window).width() < parseFloat(this.config.maxWidth)) {
				this.orientSizeParams.width = this.defaultOrientSizeParams['width'];
				this.orientSizeParams.height = this.defaultOrientSizeParams['height'];
			} else {
				this.orientSizeParams.width = this.config.maxWidth;
				this.orientSizeParams.height = '95%';
			}
		} else if (orient == 'height') {
			this.orientSizeParams.width = this.config.maxWidth;
			this.orientSizeParams.height = '95%';
		}
	}

	/**
	 * Сокрытие служебных кнопок в увеличенном окне
	 */
	processingFigureButtons($element = false) {
		const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();

		if ($cBoxLoadedContent.length) {
			const $innerFigureButtons = $cBoxLoadedContent.find('.figure-buttons');
			const $buttons = $innerFigureButtons.find('.button');

			$.each($buttons, (i, button) => {
				const $button = $(button);

				if ($button.attr('data-no-hide-in-zoom') == 'true') {
					if ($button.hasClass('js-button-excel')) {
						$button.remove();

						if ($element && $element.length) {
							addExcelExport($element);
						}
					}
				} else {
					$button.remove();
				}
			});

			this.updateColorbox({
				width: this.orientSizeParams.width,
				height: this.orientSizeParams.height
			});
		}
	}

	/**
	 * Вызов события обновление размеров ColorBox по переданным параметрам
	 * @param params
	 */
	updateColorbox(params = false) {
		if (params) {
			$.colorbox.resize(params);
		} else {
			$.colorbox.resize({
				width: this.defaultOrientSizeParams['width'],
				height: this.defaultOrientSizeParams['height']
			});
		}
	}

	/**
	 * Возвращает блок с содержимым ColorBox
	 * @returns {*}
	 */
	getCBoxLoadedContentBlock() {
		return $(this.cBoxLoadedContentSelector);
	}

	/**
	 * Возвращает главный блока ColorBox
	 * @returns {*}
	 */
	getCBoxContainer() {
		return $(this.cBoxContainerSelector);
	}

	/**
	 * Выставление атрибута title для кнопки закрытия ColorBox
	 */
	setCloseTitle() {
		$(this.cBoxCloseSelector)
			.attr('title', text.close);
	}

	/**
	 * Вызов ColorBox для содержимого типа Таблица
	 * @param $element
	 */
	actionColorBoxTable($element) {
		if ($element.length) {
			const html = $element[0].outerHTML;
			const containerClasses = $element.data('zoomInClass') ? $element.data('zoomInClass') : false;

			$.colorbox({
				maxWidth: this.getMaxWidthParam(),
				maxHeight: '95%',
				transition: 'elastic',
				opacity: this.config.opacity,
				speed: this.config.speed,
				fadeOut: this.config.speed,
				scalePhotos: true,
				className: containerClasses,
				html: html,
				onLoad: () => {
					this.setCloseTitle();
				},
				onComplete: () => {
					const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();
					const $popupElement = $cBoxLoadedContent.find('.b-table');
					const $dataTable = $cBoxLoadedContent.find('.data-table');

					this.processingFigureButtons($popupElement);

					this.updateColorbox({
						width: this.getMaxWidthParam(),
					});

					if ($dataTable.length) {
						AR.components.cTableChart_base.initTableCharts($dataTable);
					}

					AR.events.emit('onTableZoomInComplete', $cBoxLoadedContent);
				},
				onOpen: () => {
					$('body').css({overflow: 'hidden'});
				},
				onClosed: () => {
					$('body').css({overflow: ''});
				},

			});
		}
	}

	/**
	 * Вызов ColorBox для highcharts
	 * @param $element
	 */
	actionColorBoxHighcharts($element) {
		if ($element.length) {
			const $newElem = $element.clone();
			$newElem.find('[data-chart]').removeAttr('data-highcharts-chart').empty();
			const html = $newElem[0].outerHTML;
			const containerClasses = $element.data('zoomInClass') ? $element.data('zoomInClass') : false;

			$.colorbox({
				maxWidth: this.getMaxWidthParam(),
				maxHeight: '95%',
				width: this.getMaxWidthParam(),
				height: $element.height(),
				transition: 'elastic',
				speed: this.config.speed,
				fadeOut: this.config.speed,
				scalePhotos: true,
				opacity: this.config.opacity,
				className: containerClasses,
				html: html,
				onLoad: () => {
					this.setCloseTitle();
				},
				onComplete: () => {
					const chart = this.getCBoxLoadedContentBlock().find('[data-chart]')[0];
					const key = chart.dataset.chart;

					if (chart && key) {
						const initedChart = AR.components.cCharts_highcharts.initChart(chart, AR.components.cCharts_highcharts.getDataBykey(key));
						setTimeout(() => {
							initedChart.reflow();
						}, 0);
						AR.events.once('onHighchartsZoomInCleanup', () => {
							initedChart.destroy();
						});
					}

					this.processingFigureButtons();
					this.updateColorbox({
						width: this.getMaxWidthParam()
					});

					AR.events.emit('onHighchartsZoomInComplete');
				},
				onOpen: () => {
					$('body').css({overflow: 'hidden'});
				},
				onClosed: () => {
					$('body').css({overflow: ''});
				},
				onCleanup: () => {
					AR.events.emit('onHighchartsZoomInCleanup');
				}
			});
		}
	}

	/**
	 * Вызов ColorBox для любого содержимого
	 * @param $element
	 */
	actionColorBox($element) {
		if ($element.length) {
			const html = $element[0].outerHTML;
			const containerClasses = $element.data('zoomInClass') ? $element.data('zoomInClass') : false;

			$.colorbox({
				maxWidth: this.getMaxWidthParam(),
				maxHeight: '95%',
				transition: 'elastic',
				speed: this.config.speed,
				fadeOut: this.config.speed,
				scalePhotos: true,
				opacity: this.config.opacity,
				className: containerClasses,
				html: html,
				onLoad: () => {
					this.setCloseTitle();
				},
				onComplete: () => {
					const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();

					this.processingFigureButtons();
					this.updateColorbox({
						width: this.getMaxWidthParam()
					});
					AR.events.emit('onImageZoomInComplete', $cBoxLoadedContent);
				},
				onOpen: () => {
					$('body').css({overflow: 'hidden'});
				},
				onClosed: () => {
					$('body').css({overflow: ''});
				},
			});
		}
	}

	/**
	 * Вызов ColorBox для содержимого типа Картинка
	 * @param $element
	 */
	actionColorBoxImage($element) {
		if ($element.length) {
			const html = $element[0].outerHTML;
			const containerClasses = $element.data('zoomInClass') ? $element.data('zoomInClass') : false;
			const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();

			$.colorbox({
				maxWidth: this.getMaxWidthParam(),
				maxHeight: '95%',
				transition: 'elastic',
				speed: this.config.speed,
				fadeOut: this.config.speed,
				scalePhotos: true,
				opacity: this.config.opacity,
				className: containerClasses,
				html: html,
				onLoad: () => {
					this.setCloseTitle();
				},
				onComplete: () => {
					const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();

					this.processingFigureButtons();

					$cBoxLoadedContent
						.find('img')
						.imgLoad((img) => {

							// this.updateColorbox(this.getColorBoxPopupParams('IMAGE'));
							// const width = ()
							this.updateColorbox({
								width: this.getMaxWidthParam(),
								// 	// height: window.innerHeight > parseInt(cboxOptions.maxHeight) ? cboxOptions.maxHeight : cboxOptions.height
							});

							AR.events.emit('onImageZoomInComplete', $cBoxLoadedContent);
						});
				},
				onOpen: () => {
					$('body').css({overflow: 'hidden'});
				},
				onClosed: () => {
					$('body').css({overflow: ''});
				},
			});
		}
	}


	/**
	 * Вызов ColorBox для содержимого типа SVG-элемент
	 * @param $element
	 */
	actionColorBoxSVG($element) {
		const $svgContainer = $element.find('[data-svg]');
		let zoomFit = ($svgContainer.data('zoomFit')) ? $svgContainer.data('zoomFit') : false;
		const html = $element[0].outerHTML;
		const containerClasses = $element.data('zoomInClass') ? $element.data('zoomInClass') : false;

		let orientSizeParams = {};

		//Если SVG задан дата атрибут ориентации увеличения, то выставить его в настройки colorbox'a
		if (zoomFit) {
			this.setOrientSize(zoomFit);
		} else {
			//Иначе посчитать относительно пропорций SVG и окна
			let windowRatio = $(window).width() / $(window).height();
			let svgRatio = $svgContainer.data('ratio');

			if (svgRatio > windowRatio) {
				zoomFit = 'width';
				this.setOrientSize(zoomFit);
			} else {
				zoomFit = 'height';
				this.setOrientSize(zoomFit);
			}
		}

		$.colorbox({
			width: this.orientSizeParams['width'],
			height: this.orientSizeParams['height'],
			innerWidth: '500px',
			transition: 'elastic',
			speed: this.config.speed,
			fadeOut: this.config.speed,
			scalePhotos: true,
			opacity: this.config.opacity,
			className: containerClasses,
			html: html,
			onLoad: () => {
				this.setCloseTitle();
			},
			onComplete: () => {
				this.processingFigureButtons();
				const $cBoxContainer = this.getCBoxContainer();
				const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();
				const $zoomedDataSvgItem = $cBoxLoadedContent.find('[data-svg]');
				const containerIsGraphic = $zoomedDataSvgItem.parent('.graphic').length;
				const $zoomedSvgContainer = containerIsGraphic ? $zoomedDataSvgItem : $zoomedDataSvgItem.parent();

				$zoomedSvgContainer.css('width', '100%');
				const $zoomedGraphic = $cBoxLoadedContent.find('.graphic');
				const containerHeight = Math.ceil($cBoxLoadedContent.height());
				const graphicHeight = Math.ceil($zoomedGraphic.height());

				//Проверка, влезает ли контейнер после увеличения
				if (zoomFit == 'width' && !($svgContainer.data('zoomFit'))) {
					if (containerHeight < graphicHeight) {
						zoomFit = 'height';
					}
				}

				//Если нужно увеличить по высоте
				if (zoomFit == 'height') {
					let containerWidth = false;
					let colorboxWidth = this.getMaxWidthParam();

					if ((containerHeight < graphicHeight) || colorboxWidth.match('%')) {
						const contentFullHeight = $cBoxLoadedContent.height();
						let siblingsHeight = 0;

						//Определение высоты соседних элементов контейнера SVG
						if ($zoomedSvgContainer.parent().hasClass('graphic')) {
							$zoomedSvgContainer
								.siblings()
								.each(function () {
									siblingsHeight += $(this).outerHeight();
								});
						} else if (!containerIsGraphic) {
							let $parent = $zoomedDataSvgItem;
							const setParent = ($elem) => $parent = $elem.parent();
							const getPaddings = ($elem) => {
								const cssProps = $elem.css(['padding-top', 'padding-bottom']);
								const paddings = parseFloat(cssProps['padding-top']) + parseFloat(cssProps['padding-bottom']);

								return paddings;
							};
							const setExtraHeight = () => {
								setParent($parent);

								if ($parent.length && !$parent.hasClass('graphic')) {
									siblingsHeight += getPaddings($parent);

									setExtraHeight();
								}
							};

							setExtraHeight();
						}

						//Получение высоты SVG, которую нужно выставить
						let svgHeight = contentFullHeight - siblingsHeight;
						colorboxWidth = false;

						//Выставление размеров "увеличенной" SVG
						$zoomedSvgContainer
							.css('padding', 0)
							.height(svgHeight)
							.width(Math.round(svgHeight * $svgContainer.data('ratio')));

						const sidesPadding = $zoomedGraphic.css(['padding-left', 'padding-right']);
						const extraWidth = parseFloat(sidesPadding['padding-left']) + parseFloat(sidesPadding['padding-right']);

						//Получение размеров контейнера с контентом, по-которому нужно выставить размер ColorBox
						containerWidth = $zoomedGraphic
							.css('display', 'inline-block')
							.width() + extraWidth;
					}

					this.updateColorbox({
						innerWidth: Math.ceil(containerWidth),
						width: Math.ceil(colorboxWidth),
						maxHeight: '95%'
					});
				} else if (zoomFit == 'width') {
					const $svgContainerParent = $svgContainer.parent();

					if (!$svgContainerParent.hasClass('graphic')) {
						$svgContainerParent
							.css({
								width: '100%',
								height: 'auto',
								display: 'inline-block'
							});

						if ($svgContainer.css('padding-bottom').replace('px', '') == 0) {
							$svgContainer.height($svgContainer.width() * $svgContainer.data('ratio'));
						}

						this.updateColorbox(true);
					} else {
						this.updateColorbox(true);
					}
				}

				AR.events.emit('onSVGZoomInComplete', $cBoxLoadedContent);
			},
			onOpen: () => {
				$('body').css({overflow: 'hidden'});
			},
			onClosed: () => {
				$('body').css({overflow: ''});
			},
		});
	}

	/**
	 * Вызов ColorBox для содержимого типа Интерактивный график
	 * @param $element
	 */
	actionColorBoxInteractiveChart($element) {
		const $chartContainer = $element.find('.chart');
		const $caption = ($element.has('figcaption')) ? $element.find('figcaption')[0].outerHTML : '';
		const $figureButtons = ($element.has('.js-buttons')) ? $element.find('.js-buttons')[0].outerHTML : '';
		const chartID = $chartContainer.attr('id');
		const zoomedChartID = `${chartID}_zoomed`;
		const html = `${$caption}<div class="chart" id="${zoomedChartID}"></div>${$figureButtons}`;
		const containerClasses = $element.data('zoomInClass') ? $element.data('zoomInClass') : false;
		const chartRatio = $chartContainer.width() / $chartContainer.height();
		let zoomFit = ($chartContainer.data('zoomFit')) ? $chartContainer.data('zoomFit') : false;

		//Если SVG задан дата атрибут ориентации увеличения, то выставить его в настройки colorbox'a
		if (zoomFit) {
			this.setOrientSize(zoomFit);
		} else {
			//Иначе посчитать относительно пропорций SVG и окна
			let windowRatio = $(window).width() / $(window).height();

			if (chartRatio > windowRatio) {
				zoomFit = 'width';
				this.setOrientSize(zoomFit);
			} else {
				zoomFit = 'height';
				this.setOrientSize(zoomFit);
			}
		}

		$.colorbox({
			width: this.orientSizeParams['width'],
			height: this.orientSizeParams['height'],
			innerWidth: '500px',
			transition: 'elastic',
			speed: this.config.speed,
			fadeOut: this.config.speed,
			scalePhotos: true,
			className: containerClasses,
			html: html,
			onLoad: () => {
				this.setCloseTitle();
			},
			onComplete: () => {
				const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();

				initChartByContainerID(`#${chartID}`, `#${zoomedChartID}`, () => {
					const $cBoxContainer = this.getCBoxContainer();
					const $cBoxLoadedContent = this.getCBoxLoadedContentBlock();
					const $zoomedChartContainer = $cBoxLoadedContent.find('.chart');
					const $zoomedSvgChart = $zoomedChartContainer.find('svg');

					this.processingFigureButtons();

					$zoomedSvgChart.css({
						width: '100%',
						height: '100%',
					});

					$zoomedChartContainer.css('width', '100%');

					this.updateColorbox(true);

					const containerHeight = Math.ceil($cBoxLoadedContent.height());
					const chartHeight = Math.ceil($zoomedChartContainer.height());

					//Проверка, влезает ли контейнер после увеличения
					if (zoomFit == 'width' && !($chartContainer.data('zoomFit'))) {
						if (containerHeight < chartHeight) {
							zoomFit = 'height';
						}
					}

					//Если нужно увеличить по высоте
					if (zoomFit == 'height') {
						let containerWidth = false;
						let colorboxWidth = this.getMaxWidthParam();

						let chartHeight = $zoomedChartContainer.height();
						colorboxWidth = false;

						//Получение размеров контейнера с контентом, по-которому нужно выставить размер ColorBox
						$zoomedChartContainer
							.css('display', 'inline-block')
							.width();

						this.updateColorbox({
							innerWidth: containerWidth,
							width: colorboxWidth,
							maxHeight: '95%'
						});
					} else if (zoomFit == 'width') {
						this.updateColorbox(true);
					}
				});

				AR.events.emit('onChartZoomInComplete', $cBoxLoadedContent);
			},
			onOpen: () => {
				$('body').css({overflow: 'hidden'});
			},
			onClosed: () => {
				$('body').css({overflow: ''});
			},
		});

		return false;
	}

	/**
	 * Тип обрабатываемого контента
	 * @param $element
	 * @returns {boolean}
	 */
	static getElementType($element) {
		let type = false;

		if ($element.has('table').length) {
			type = 'TABLE';
		} else if ($element.has('[data-svg]').length) {
			type = 'SVG';
		} else if ($element.has('img').length) {
			type = 'IMAGE';
		} else if ($element.has('.chart').length && $element.has('svg').length) {
			type = 'INTERACTIVE_CHART';
		} else if ($element.find('[data-highcharts-chart]').length) {
			type = 'HIGHCHARTS';
		} else {
			return 'OTHER';
		}

		return type;
	}

	/**
	 * Обработка параметра максимальной ширины окошка ColorBox
	 * @returns {*}
	 */
	getMaxWidthParam() {
		let maxWidth = this.config.maxWidth || '1024px';

		if (!maxWidth.match('%')) {
			let maxWidthNum = Number(maxWidth.replace('px', ''));

			if (maxWidthNum > $(window).width()) {
				maxWidth = '95%';
			}
		}

		return maxWidth;
	}

	/**
	 * Добавить кнопку увеличения
	 */
	addZoomIn($elements) {
		$elements.each((i, item) => {
			const $element = $(item);
			const element = $element[0];
			const $button = $(`<button class="button button--zoom js-button-zoom" title="${this.buttonTitle}">${this.buttonTitle}</button>`);

			AR.events.emit('onButtonHasAdded', [$button, 'zoomIn']);

			$element.find('.figure-buttons.js-buttons')
				.append($button)
				.find('.js-button-zoom')
				.on('click', event => this.registerEvent(event, element));

			if (config.contentClickable) {
				$element.find('.graphic__img, .chart')
					.on('click', event => this.registerEvent(event, element))
					.addClass('clickable');
			}
		});
	}
}

AR.waitComponents([], () => {
	const cZoomIn_base = new CZoomIn_base();
	// Вызов метода со всеми событиями
	cZoomIn_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cZoomIn_base, 'cZoomIn_base');
});
