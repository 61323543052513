import {throttle} from 'throttle-debounce';
import CAligner from '../../index.js';
import config from './config.yaml';

/**
 * Реализует логику варианта graphics компонента aligner
 */
class CAligner_graphics extends CAligner {
	constructor() {
		super();
		this.config = config;
		this.unloaded = 0;
		this.loaded = 0;
	}

	/**
	 * Инициализирование
	 */
	init() {
		const rowsObjs = this.getElementsObj(document.querySelectorAll('.graphic:not(.no-align)'));

		if (rowsObjs.length) {
			this.addHelpers(rowsObjs);
			this.initAlignHandlers(rowsObjs);
		}
	}

	/**
	 * Получает объекты графиков
	 * @param {nodeList, array} rowsElems Элементы-графики
	 */
	getElementsObj(rowsElems, initial = true) {
		let array = [...rowsElems];

		return array.map(elem => {
			let image = elem.querySelector('[data-svg], img');
			let type = this.getImageType(image);
			let parentClass = elem.parentElement.getAttribute('class');

			return {
				elem,
				image,
				type,
				isInCol: parentClass ? parentClass.includes('col-') : false,
				wrap: initial ? null : elem.parentElement
			};
		});
	}

	/**
	 * Получает статус загрузки картинки графика
	 * @param {object} image Картинка
	 * @param {string} type  Тип (img, svg, etc.)
	 * @param {array} objs  Объекты графиков
	 */
	getLoadState(image, type, objs) {
		let loadState = false;
		let onload = () => {
			this.loaded++;
			this.unloaded--;

			this.processElements(objs);
		};

		if (type == 'svg') {
			loadState = image.querySelector('svg');

			if (!loadState) {
				AR.events.on('svgRendered', $svg => {
					if ($svg.parent()[0].dataset.svg == image.dataset.svg) {
						onload();
					}
				});
			}
		} else if (type == 'img') {
			loadState = image.complete;

			if (!loadState) {
				image.onload = () => {
					onload();
				};
			}
		}

		if (loadState) {
			this.loaded++;
		} else {
			this.unloaded++;
		}

		return loadState;
	}

	/**
	 * Получение типа картинки
	 * @param {object} image картинка графика
	 */
	getImageType(image) {
		if (image) {
			if (image.dataset.svg) {
				return 'svg';
			} else if (image.tagName == 'IMG') {
				return 'img';
			}
		}

		return 'unknown';
	}

	/**
	 * Обернуть графики в вспомогательные блоки
	 * @param {array} objs объекты графиков
	 */
	addHelpers(objs) {
		const imageWrap = '<div class="aligner-gap"></div>';

		objs.forEach(obj => {
			$(obj.image).before(imageWrap);

			if (obj.type == 'svg' && obj.image.parentElement.classList.contains('graphic')) {
				$(obj.image).wrap(document.createElement('div'));
			}

			let wrap = document.createElement('div');
			wrap.classList.add(this.config.wrapperClass);
			$(obj.elem).wrap(wrap);
			obj.wrap = obj.elem.parentElement;
		});
	}

	/**
	 * Инициализцаия обработчиков
	 * @param {array} rowsObjs графики
	 */
	initAlignHandlers(rowsObjs) {
		this.processElements(rowsObjs, true);

		AR.events.on('onTabsHorizontalOpen', $targetArticle => {
			this.processElements(this.getElementsObj($targetArticle.find('.graphic'), false));
		});

		AR.events.on('onTabsVerticalOpen', $targetArticle => {
			this.processElements(this.getElementsObj($targetArticle.find('.graphic'), false));
		});

		AR.events.on('onAccordionsBaseOpenStart', $button => {
			setTimeout(() => {
				this.processElements(this.getElementsObj($button.closest('.js-accordion-section').find('.graphic'), false));
			}, 50);
		});

		$(window).resize(throttle(150, () => {
			this.processElements(rowsObjs);
		}));
	}

	/**
	 * Обработать графики
	 * @param {array}  rowsElems графики
	 * @param {boolean} initial инициирующий вызов
	 */
	processElements(rowsObjs, initial) {
		if (rowsObjs.length) {
			rowsObjs.forEach(obj => {
				obj.elem.classList.remove('graphic--aligned');
				obj.wrap.style.height = '';
			});

			const objsByRow = this.detectRow(rowsObjs);
			this.alignIt(objsByRow, initial);
		}
	}

	/**
	 * Выровнять графики
	 * @param {array}   rowsObjs графики
	 * @param {boolean} initial инициирующий вызов
	 */
	alignIt(rowsObjs, initial) {
		rowsObjs.forEach(rowItems => {
			let highest = 0;

			rowItems.forEach(obj => {
				const $elem = $(obj.elem);
				const height = $elem.outerHeight(true);

				if (initial) {
					this.getLoadState(obj.image, obj.type, rowItems);
				}

				if (height > highest) {
					highest = height;
				}
			});

			if (highest !== 0) {
				rowItems.forEach(obj => {
					obj.elem.classList.add('graphic--aligned');

					if (obj.isInCol) {
						obj.wrap.style.height = '100%';
					} else {
						obj.wrap.style.height = highest + 'px';
					}
				});
			}
		});
	}

	/**
	 * Раскидать элементы по строкам (в отдельные массивы)
	 * @param {array} rowsObjs графики
	 */
	detectRow(rowsObjs) {
		const itemsByRow = [];
		let currentTop = rowsObjs[0].elem.offsetTop;
		let levelArr = [];

		rowsObjs.forEach(obj => {
			if (obj.elem.offsetTop == currentTop) {
				levelArr.push(obj);
			} else {
				if (levelArr.length > 1) {
					itemsByRow.push(levelArr);
				}

				levelArr = [];
				levelArr.push(obj);
				currentTop = obj.elem.offsetTop;
			}
		});

		if (levelArr.length > 1) {
			itemsByRow.push(levelArr);
		}

		return itemsByRow;
	}
}

AR.waitComponents([], () => {
	const cAligner_graphics = new CAligner_graphics;
	// Вызов метода, инициализирующего все существующие события
	cAligner_graphics.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cAligner_graphics, 'cAligner_graphics');
});
