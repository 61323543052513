const config = require('./config.yaml');
const CMenu = require('../../index.js');

/**
 * Реализует логику варианта horizontal-wide-page компонента menu
 */
class CMenu_horizontalWidePage extends CMenu {
	constructor() {
		super();
		this.config = config;
		// Тип меню для определения класса
		this.menuType = 'top';
		// Имя варианта для создания глобальных событий
		this.eventNameVariantPart = 'horizontalWidePage';
	}

	/**
	 * Инициализация
	 */
	async init() {
		// Меню
		this.$menu = $('.js-top-nav--horizontal-wide-page');

		// Если меню на странице существует
		if (this.$menu.length) {
			this.$body = $('body');
			// Бургер (триггер, кнопка раскрытия)
			this.$navBurger = this.$menu.find('.js-nav-burger');
			// Панель (плашка на весь экран с меню)
			this.$navPanel = this.$menu.find('.js-nav-panel');
			// Все элементы ссылок в меню
			this.$links = this.$menu.find('.js-top-link');
			// Триггеры раскрытия списков у пунктов в меню
			this.$arrows = this.$menu.find('.js-nav-arrow');
			// Класс списков (выпадашек)
			this.listClass = '.js-sub-space, .js-top-list';

			// Установить события, на которые раскрывать / закрывать списки меню
			this.setOpenHideEvents();
			// По установленному событию на ссылки с подменю инициализировать раскрытие
			this.$arrows.on(this.openEvents, $.proxy(this, 'onToggle'));
			this.$navBurger.on('click', $.proxy(this, 'onTogglePanel'));

			// Если меню необходимо делать фиксируемым
			if (this.config.fixedMenu) {
				// Инициализировать наблюдателя состояния фиксирующегося меню
				this.initFixMenuWatcher();
			}

			// Инициализация катсомного скролла
			this.scrollbarInMenu = await this.initScrollbar();
		}
	}

	/**
	 * Инициализация раскрытия / закрытия списка
	 * @param  {Object} event Событие клика / тапа / наведения курсора на пункт меню
	 */
	onToggle(event) {
		// Пункт меню, из которого будет раскрыт список
		const $this = $(event.currentTarget);
		const $item = $this.siblings('.js-top-link');

		// Если список не раскрыт
		if (!$item.hasClass('is-expand')) {
			// Если списки необходимо раскрывать по наведению и пункт меню первого уровня
			if (this.config.hoverable && $item.hasClass(`js-${this.menuType}-link-lvl1`) && event.type !== 'touchstart') {
				// Раскрытые элементы первого уровня
				const expandedElementsLength = this.$menu.find(`.js-${this.menuType}-link-lvl1.is-expand`).length;

				// Очистить timeout
				clearInterval(this.hoverShowMenuTimeout);
				this.hoverShowMenuTimeout = setTimeout(() => {
					// Раскрыть список
					this.open($item);
				}, this.config.hoverableDelay && expandedElementsLength ? this.config.hoverableDelay : 0);
			} else {
				event.preventDefault();
				// Раскрыть список
				this.open($item);
			}
		} else {
			event.preventDefault();
			// Раскрыть список
			this.close($item);
		}
	}


	/**
	 * Раскрытие списка
	 * @param  {Object} $element Элемент список
	 */
	open($element) {
		// Эффект и продолжительность анимации
		const {openEffect, openDuration} = this.getOpenAnimation($element);

		if (this.config.closeSiblingsOnOpen) { // Раскрытые списков на текущем уровне
			const $expandedSiblings = $element
				.closest(`.js-${this.menuType}-item`)
				.siblings()
				.find(`.js-${this.menuType}-link.is-expand`);
			if ($expandedSiblings.length) {
				this.close($expandedSiblings, true);
			}
		}

		// Раскрыть список
		$element
			.addClass('is-expand')
			.parents('.b-nav__link-wrapper')
			.siblings(this.listClass)
			.velocity(openEffect, {
				duration: openDuration,
				// Стригерить глобальное событие начала раскрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenStart`, $(element)),
				// Стригерить глобальное событие завершения раскрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}OpenEnd`, $(element))
			});
	}

	/**
	 * Закрытие списка
	 * @param  {Object}  $element      Элемент список
	 * @param  {Boolean} closeForNext  Делается ли закрытие списка перед открытием следующего
	 */
	close($element, closeForNext) {
		// Эффект и продолжительность анимации
		const {closeEffect, closeDuration} = this.getCloseAnimation($element, closeForNext);

		$element
			.removeClass('is-expand')
			.parents('.b-nav__link-wrapper')
			.siblings(this.listClass)
			.velocity(closeEffect, {
				duration: closeDuration,
				// Стригерить глобальное событие начала закрытия списка
				begin: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseStart`, $(element)),
				// Стригерить глобальное событие завершения закрытия списка
				complete: (element) => AR.events.emit(`onMenu${this.eventNameVariantPart}CloseEnd`, $(element))
			});
	}

	/**
	 * Управление открытием / закрытием панели
	 */
	onTogglePanel() {
		if (this.$navBurger.hasClass('is-expand')) {
			$(document).off(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.$navBurger.removeClass('is-expand');
			this.closePanel();
		} else {
			$(document).on(this.hideEvents, $.proxy(this, 'onDocumentListener'));
			this.$navBurger.addClass('is-expand');
			this.openPanel();
		}
	}

	/**
	 * Открытие панели
	 */
	openPanel() {
		const {openEffect, openDuration} = this.getOpenAnimation(null, true);

		this.$navPanel
			.velocity('stop')
			.velocity(openEffect, {
				duration: openDuration,
				begin: () => {
					this.lock();
				}
			});
	}

	/**
	 * Закрытие панели
	 */
	closePanel() {
		const {closeEffect, closeDuration} = this.getCloseAnimation(null, false, true);
		const $expandedElements = this.$menu.find('.is-expand');

		this.$navPanel
			.velocity('stop')
			.velocity(closeEffect, {
				duration: closeDuration,
				begin: () => {
					this.unlock();
				}
			});

		if ($expandedElements.length) {
			this.close($expandedElements);
		}
	}

	/**
	 * Инициализация скроллбара
	 */
	initScrollbar() {
		return AR.components.cScrollbar_base.initScrollbar(this.$menu.find('.js-menu-scrollbar'));
	}

	/**
	 * Блокировка
	 */
	lock() {
		const scrollWidth = window.innerWidth - document.body.clientWidth;

		this.scrollTop = $(document).scrollTop();
		this.$body
			.css('margin-right', '')// .css('margin-right', `${scrollWidth}px`) убрал потому что вот так https://take.ms/yiQqL
			.addClass('is-locked');
		this.$navPanel.css('width', '');
	}

	/**
	 * Разблокировка
	 */
	unlock() {
		const scrollWidth = window.innerWidth - document.body.clientWidth;

		this.$body
			.css('margin-right', '')
			.removeClass('is-locked');
		this.$navPanel.css('width', `calc(100% + ${scrollWidth}px)`);
		$(document).scrollTop(this.scrollTop);
	}

	/**
	 * Получение эффекта и продолжительности анимации раскрытия
	 * @param  {Object} $element   Элемент пункт
	 * @param  {Boolean} isPanel Является основной оберткой списка
	 * @return {Object}            Эффект и время анимации
	 */
	getOpenAnimation($element, isPanel) {
		// Если пункт первого уровня
		if (isPanel) {
			return {
				openEffect: this.config.animation.listWrap.open.transition,
				openDuration: this.config.animation.listWrap.open.duration
			};
		} else if ($element.hasClass('js-top-link-lvl1')) {
			return {
				openEffect: this.config.animation.main.open.transition,
				openDuration: this.config.animation.main.open.duration
			};
		} else {
			return {
				openEffect: this.config.animation.inner.open.transition,
				openDuration: this.config.animation.inner.open.duration
			};
		}
	}

	/**
	 * Получение эффекта и продолжительности анимации закрытия
	 * @param  {Object}  $element     Элемент пункт
	 * @param  {Boolean} closeForNext Делается ли закрытие списка перед открытием следующего
	 * @param  {Boolean} isPanel    Является основной оберткой списка
	 * @return {Object}               Эффект и время анимации
	 */
	getCloseAnimation($element, closeForNext, isPanel) {
		// Если нужно делать закрытие перед открытием следующего и элемент второго уровня
		if (isPanel) {
			return {
				closeEffect: this.config.animation.listWrap.close.transition,
				closeDuration: this.config.animation.listWrap.close.duration
			};
		} else if (closeForNext && $element.hasClass('js-top-link-lvl1')) {
			return {
				closeEffect: this.config.animation.main.close.transition,
				closeDuration: this.config.animation.main.close.duration
			};

		} else {
			return {
				closeEffect: this.config.animation.inner.close.transition,
				closeDuration: this.config.animation.inner.close.duration
			};
		}
	}

	/**
	 * Обработчик события клика / тапа / наведения курсора на документ
	 * @param  {Object} event Событие
	 */
	onDocumentListener(event) {
		// Обращаемся к родительскому методу
		if (!$(event.target).closest(this.$menu).length) {
			const $expandedElements = this.$menu.find('.is-expand');
			// Закрыть все раскрытые списки
			this.close($expandedElements);
			this.closePanel();
		}
	}
}

AR.waitComponents(['cScrollbar_base'], () => {
	const cMenu_horizontalWidePage = new CMenu_horizontalWidePage();
	// Вызов метода, инициализирующего все существующие события
	cMenu_horizontalWidePage.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cMenu_horizontalWidePage, 'CMenu_horizontalWidePage');
});
