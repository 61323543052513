module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'social-services/static-in-line' && templateVars.config ?
				templateVars.config :
				{
	"animationClose": "transition.slideDownOut",
	"animationCloseDuration": 200,
	"animationOpen": "transition.slideUpIn",
	"animationOpenDuration": 200,
	"links": {
		"ru": [
			{
				"modifier": "linkedin",
				"targetBlank": true,
				"url": "https://ru.linkedin.com/company/jsc-national-company-kazmunaygas",
				"text": "Linkedin"
			},
			{
				"modifier": "instagram",
				"targetBlank": true,
				"url": "https://www.instagram.com/kazmunaygaspress/",
				"text": "Instagram"
			},
			{
				"modifier": "facebook",
				"targetBlank": true,
				"url": "https://www.facebook.com/kazmunaygaspress/",
				"text": "Facebook"
			},
			{
				"modifier": "youtube",
				"targetBlank": true,
				"url": "https://www.youtube.com/user/KazMunaiGas/",
				"text": "YouTube"
			}
		],
		"en": [
			{
				"modifier": "linkedin",
				"targetBlank": true,
				"url": "https://ru.linkedin.com/company/jsc-national-company-kazmunaygas",
				"text": "Linkedin"
			},
			{
				"modifier": "instagram",
				"targetBlank": true,
				"url": "https://www.instagram.com/kazmunaygaspress/",
				"text": "Instagram"
			},
			{
				"modifier": "facebook",
				"targetBlank": true,
				"url": "https://www.facebook.com/kazmunaygaspress/",
				"text": "Facebook"
			},
			{
				"modifier": "youtube",
				"targetBlank": true,
				"url": "https://www.youtube.com/user/KazMunaiGas/",
				"text": "YouTube"
			}
		],
		"kz": [
			{
				"modifier": "linkedin",
				"targetBlank": true,
				"url": "https://ru.linkedin.com/company/jsc-national-company-kazmunaygas",
				"text": "Linkedin"
			},
			{
				"modifier": "instagram",
				"targetBlank": true,
				"url": "https://www.instagram.com/kazmunaygaspress/",
				"text": "Instagram"
			},
			{
				"modifier": "facebook",
				"targetBlank": true,
				"url": "https://www.facebook.com/kazmunaygaspress/",
				"text": "Facebook"
			},
			{
				"modifier": "youtube",
				"targetBlank": true,
				"url": "https://www.youtube.com/user/KazMunaiGas/",
				"text": "YouTube"
			}
		]
	}
};