module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'key-indicator/counter' && templateVars.config ?
				templateVars.config :
				{
	"trimZeroOnEnd": false,
	"startVal": 0,
	"duration": 3,
	"decimalPlaces": 0,
	"options": {
		"ru": {
			"useEasing": true,
			"easingFn": null,
			"useGrouping": true,
			"separator": " ",
			"decimal": ","
		},
		"en": {
			"useEasing": true,
			"easingFn": null,
			"useGrouping": true,
			"separator": ",",
			"decimal": "."
		}
	}
};