module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'accordions/base' && templateVars.config ?
				templateVars.config :
				{
	"showArticleCloseBtnCondition": false,
	"closeInsideAccordionsOnClose": true,
	"scrollOnOpen": false,
	"scrollOnClose": false,
	"closeSiblings": false,
	"animation": {
		"open": {
			"effect": "slideDown",
			"duration": 400
		},
		"close": {
			"effect": "slideUp",
			"duration": 400
		},
		"scrollTo": {
			"duration": 400
		}
	}
};