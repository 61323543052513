module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'analysis/base' && templateVars.config ?
				templateVars.config :
				{
	"scrollToChartDuration": 500,
	"config": {
		"colors": [
			"#1f77b4",
			"#aec7e8",
			"#ff7f0e",
			"#ffbb78",
			"#2ca02c",
			"#98df8a",
			"#d62728",
			"#ff9896",
			"#9467bd",
			"#c5b0d5",
			"#8c564b",
			"#c49c94",
			"#e377c2",
			"#f7b6d2",
			"#7f7f7f",
			"#c7c7c7",
			"#bcbd22",
			"#dbdb8d",
			"#17becf"
		],
		"selectionMaximum": 7,
		"disableHash": true
	},
	"data": {
		"presets": [
			{
				"id": 1,
				"title": {
					"ru": "Улица Герцена",
					"en": "Gertzen Street"
				},
				"items": [
					1,
					6,
					7
				]
			},
			{
				"id": 2,
				"title": {
					"ru": "Гастроном №22",
					"en": "Supermarket 22"
				},
				"items": [
					4,
					7,
					8
				]
			},
			{
				"id": 3,
				"title": {
					"ru": "Библиотека",
					"en": "Library"
				},
				"items": [
					4,
					5,
					6,
					7
				]
			}
		],
		"years": [
			2010,
			2011,
			2012,
			2013,
			2014
		],
		"units": {
			"млн т н. э.": {
				"ru": "млн т н. э.",
				"en": "mn TOE"
			},
			"%": "%",
			"млн т": {
				"ru": "млн т",
				"en": "mn t"
			},
			"шт": {
				"ru": "шт",
				"en": "units"
			},
			"т/сут.": {
				"ru": "т/сут.",
				"en": "tonnes per day"
			},
			"млрд руб.": {
				"ru": "млрд руб.",
				"en": "bn RUB"
			},
			"млн руб.": {
				"ru": "млн руб.",
				"en": "mn RUB"
			},
			"руб.": {
				"ru": "руб.",
				"en": "RUB"
			},
			"млн шт.": {
				"ru": "млн шт.",
				"en": "mn"
			},
			"руб./барр. н. э.": {
				"ru": "руб./барр. н. э.",
				"en": "RUB/BOE"
			},
			"ratio": {
				"ru": "ед.",
				"en": "ratio"
			},
			"LTIFR": {
				"ru": "ед.",
				"en": "LTIFR"
			},
			"чел.": {
				"ru": "чел.",
				"en": "persons"
			}
		},
		"groups": [
			{
				"id": 1,
				"title": {
					"ru": "Основные операционные показатели",
					"en": "Key operating indicators"
				},
				"items": [
					{
						"id": 1,
						"title": {
							"ru": "Доказанные запасы углеводородов  (PRMS-SPE)",
							"en": "Proved hydrocarbon reserves (PRMS-SPE)"
						},
						"units": "млн т н. э.",
						"values": [
							1023,
							1130,
							1200,
							1343,
							1443
						],
						"className": "customClass"
					},
					{
						"id": 2,
						"title": {
							"ru": "Коэффициент восполнения запасов углеводородов по категории 1Р",
							"en": "Hydrocarbon reserve replacement ratio under category 1Р"
						},
						"units": "%",
						"values": [
							110,
							291,
							222,
							336,
							254
						]
					},
					{
						"id": 3,
						"title": {
							"ru": "Соотношение переработка/добыча",
							"en": "Refining/production ratio"
						},
						"units": "%",
						"values": [
							76,
							81,
							85,
							84,
							84
						]
					},
					{
						"id": 4,
						"title": {
							"ru": "Добыча товарных углеводородов Группой компаний «Название компании»",
							"en": "Production of marketable hydrocarbons by the Company name"
						},
						"units": "млн т н. э.",
						"values": [
							52.81,
							57.25,
							59.71,
							62.26,
							66.25
						]
					},
					{
						"id": 5,
						"title": {
							"ru": "Переработка нефти",
							"en": "Oil refining"
						},
						"units": "млн т",
						"values": [
							37.9,
							40.49,
							43.34,
							42.63,
							43.48
						]
					},
					{
						"id": 6,
						"className": "subgroup",
						"title": {
							"ru": "на собственных НПЗ",
							"en": "at own refineries"
						},
						"units": "млн т",
						"values": [
							30.75,
							33.1,
							33.76,
							34.06,
							34.66
						]
					},
					{
						"id": 7,
						"className": "subgroup",
						"title": {
							"ru": "на привлеченных НПЗ",
							"en": "at contracted refineries"
						},
						"units": "млн т",
						"values": [
							7.15,
							7.39,
							9.58,
							8.57,
							8.82
						]
					},
					{
						"id": 8,
						"title": {
							"ru": "Экспорт нефти",
							"en": "Oil exports"
						},
						"units": "млн т н. э.",
						"values": [
							18.96,
							16.07,
							16.62,
							12.34,
							9.63
						]
					},
					{
						"id": 9,
						"title": {
							"ru": "Объем продаж нефтепродуктов",
							"en": "Sales of petroleum products"
						},
						"units": "млн т",
						"values": [
							37.2,
							41.5,
							44.03,
							45.74,
							48.3
						]
					},
					{
						"id": 10,
						"title": {
							"ru": "Экспорт нефтепродуктов",
							"en": "Export of petroleum products"
						},
						"units": "млн т",
						"values": [
							14.05,
							14.68,
							16.48,
							16.82,
							17.73
						]
					},
					{
						"id": 11,
						"title": {
							"ru": "Количество действующих АЗС ",
							"en": "Number of active petrol stations "
						},
						"units": "шт",
						"values": [
							1596,
							1670,
							1609,
							1747,
							1810
						]
					},
					{
						"id": 12,
						"title": {
							"ru": "Средний объем реализации нефтепродуктов через одну АЗС ",
							"en": "Average sales of petroleum products per filling station"
						},
						"units": "т/сут.",
						"values": [
							10.3,
							14.2,
							17.6,
							19,
							20
						]
					}
				]
			},
			{
				"id": 2,
				"title": {
					"ru": "Основные показатели в области устойчивого развития",
					"en": "Key sustainable development indicators"
				},
				"items": [
					{
						"id": 13,
						"title": {
							"ru": "Среднесписочная численность персонала",
							"en": "Average number of personnel"
						},
						"units": "чел.",
						"values": [
							64895,
							58905,
							65829,
							55975,
							57515
						]
					},
					{
						"id": 14,
						"title": {
							"ru": "Затраты на реализацию программы «Родные города»",
							"en": "Cost of implementing “Home Towns” programme"
						},
						"units": "млрд руб.",
						"values": [
							null,
							null,
							2.4,
							3.7,
							4.5
						]
					},
					{
						"id": 15,
						"title": {
							"ru": "Коэффициент травматизма (LTIFR)",
							"en": "Injury rate (LTIFR)"
						},
						"units": "LTIFR",
						"values": [
							0.59,
							0.64,
							0.65,
							0.44,
							0.52
						]
					}
				]
			},
			{
				"id": 3,
				"title": {
					"ru": "Налоговые платежи в консолидированный бюджет Российской Федерации",
					"en": "Tax payments to the consolidated budget of the Russian Federation"
				},
				"items": [
					{
						"id": 16,
						"title": {
							"ru": "Фискальные платежи,:",
							"en": "Fiscal payments"
						},
						"units": "млн руб.",
						"values": [
							317302,
							482628,
							523467,
							489136,
							560085
						]
					},
					{
						"id": 17,
						"title": {
							"ru": "Федеральный бюджет",
							"en": "Federal budget"
						},
						"units": "млн руб.",
						"values": [
							104199,
							195859,
							234247,
							245175,
							278197
						]
					},
					{
						"id": 18,
						"title": {
							"ru": "Региональный бюджет",
							"en": "Regional budget"
						},
						"units": "млн руб.",
						"values": [
							26044,
							45350,
							32605,
							37718,
							36209
						]
					},
					{
						"id": 19,
						"title": {
							"ru": "Внебюджетные фонды",
							"en": "Extra-budgetary funds"
						},
						"units": "млн руб.",
						"values": [
							2157,
							4379,
							5898,
							7208,
							9489
						]
					},
					{
						"id": 20,
						"title": {
							"ru": "Экспортные пошлины",
							"en": "Export duties"
						},
						"units": "млн руб.",
						"values": [
							184902,
							237041,
							250717,
							199035,
							236190
						]
					}
				]
			},
			{
				"id": 4,
				"title": {
					"ru": "Основные финансовые показатели",
					"en": "Key financial indicators"
				},
				"items": [
					{
						"id": 21,
						"title": {
							"ru": "Продажи",
							"en": "Sales"
						},
						"units": "млн руб.",
						"values": [
							999498,
							1291596,
							1519450,
							1504037,
							1690557
						]
					},
					{
						"id": 22,
						"title": {
							"ru": "Выручка от реализации",
							"en": "Sales revenue"
						},
						"units": "млн руб.",
						"values": [
							793871,
							1029803,
							1232649,
							1267603,
							1408238
						]
					},
					{
						"id": 23,
						"title": {
							"ru": "Скорректированная EBITDA",
							"en": "Adjusted EBITDA"
						},
						"units": "млн руб.",
						"values": [
							220812,
							300077,
							323106,
							336752,
							342614
						]
					},
					{
						"id": 24,
						"title": {
							"ru": "Чистая прибыль, относящаяся к «Название компании»",
							"en": "Net profit attributable to Company name"
						},
						"units": "млн руб.",
						"values": [
							95692,
							160362,
							176296,
							177917,
							122093
						]
					},
					{
						"id": 25,
						"title": {
							"ru": "Чистые денежные средства, полученные от операционной деятельности",
							"en": "Net cash generated from operations"
						},
						"units": "млн руб.",
						"values": [
							163718,
							180871,
							247748,
							276736,
							283965
						]
					},
					{
						"id": 26,
						"title": {
							"ru": "Капитальные вложения",
							"en": "Capital investments"
						},
						"units": "млн руб.",
						"values": [
							100247,
							29157,
							169213,
							208611,
							271330
						]
					},
					{
						"id": 27,
						"title": {
							"ru": "Дивиденды выплаченные",
							"en": "Dividends paid"
						},
						"units": "млн руб.",
						"values": [
							21051,
							34611,
							44094,
							44473,
							21905
						]
					},
					{
						"id": 28,
						"title": {
							"ru": "Чистая задолженность",
							"en": "Net debt"
						},
						"units": "млн руб.",
						"values": [
							165398,
							191628,
							156922,
							185922,
							433602
						]
					},
					{
						"id": 29,
						"title": {
							"ru": "Цена акции на&nbsp;конец года (ММВБ)",
							"en": "Share price for the end of the year (MICEX)"
						},
						"units": "руб.",
						"values": [
							128.27,
							148.18,
							142.52,
							146.77,
							143
						]
					},
					{
						"id": 30,
						"title": {
							"ru": "Всего акций",
							"en": "Total shares"
						},
						"units": "млн шт.",
						"values": [
							4741,
							4741,
							4741,
							4741,
							4741
						]
					},
					{
						"id": 31,
						"title": {
							"ru": "из них казначейских",
							"en": "including treasuries"
						},
						"units": "млн шт.",
						"className": "subgroup",
						"values": [
							23,
							23,
							23,
							23,
							23
						]
					},
					{
						"id": 32,
						"title": {
							"ru": "Базовая и разводненная прибыль на одну обыкновенную акцию",
							"en": "Base and diluted earnings per common share"
						},
						"units": "руб.",
						"values": [
							20.35,
							33.99,
							37.37,
							37.71,
							25.88
						]
					},
					{
						"id": 33,
						"title": {
							"ru": "Дивиденд на акцию",
							"en": "Dividend per share"
						},
						"units": "руб.",
						"values": [
							4.44,
							7.3,
							9.3,
							9.38,
							4.62
						]
					},
					{
						"id": 34,
						"title": {
							"ru": "Доход на средний используемый капитал",
							"en": "Return on average capital employed"
						},
						"units": "%",
						"values": [
							15.83,
							20.58,
							19.38,
							17.44,
							13.07
						]
					},
					{
						"id": 35,
						"title": {
							"ru": "Рентабельность скорректированной EBITDA",
							"en": "Adjusted EBITDA margin"
						},
						"units": "%",
						"values": [
							27.81,
							29.14,
							26.21,
							26.57,
							24.33
						]
					},
					{
						"id": 36,
						"title": {
							"ru": "Скорректированная EBITDA на баррель добычи",
							"en": "Adjusted EBITDA per barrel produced"
						},
						"units": "руб./барр. н. э.",
						"values": [
							566.99,
							711.7,
							735.97,
							736.2,
							701.93
						]
					},
					{
						"id": 37,
						"title": {
							"ru": "Рентабельность чистой прибыли",
							"en": "Net profit margin"
						},
						"units": "%",
						"values": [
							12.05,
							16.28,
							14.94,
							14.73,
							8.99
						]
					},
					{
						"id": 38,
						"title": {
							"ru": "Чистая прибыль на баррель добычи",
							"en": "Net profit per barrel produced"
						},
						"units": "руб./барр. н. э.",
						"values": [
							268.16,
							397.67,
							419.46,
							408.2,
							259.48
						]
					},
					{
						"id": 39,
						"title": {
							"ru": "Рентабельность акционерного капитала",
							"en": "Return on equity"
						},
						"units": "%",
						"values": [
							17.91,
							24.99,
							23.03,
							19.99,
							11.9
						]
					},
					{
						"id": 40,
						"title": {
							"ru": "Доля заемных средств (Gearing)",
							"en": "Leverage ratio (Gearing)"
						},
						"units": "%",
						"values": [
							20.48,
							20.81,
							15.28,
							15.7,
							27.73
						]
					},
					{
						"id": 41,
						"title": {
							"ru": "Коэффициент текущей ликвидности",
							"en": "Current ratio"
						},
						"units": "ratio",
						"values": [
							1.44,
							1.98,
							1.75,
							2.08,
							1.88
						]
					}
				]
			}
		]
	}
};